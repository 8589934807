<template>
  <b-card-code :title="this.$route.meta.pageTitle">
    <div class="text-center pt-4" v-if="loading">
      <b-spinner label="Cargando..."/>
      Cargando...
    </div>
    <div>
      <b-row class="mb-1">
        <b-col>
          <b-form-group
              label="N° Documento Control de Vectores"
              label-for="name"
          >
            <b-form-input
                id="name"
                v-model="item.digesaId"
                placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="N Documento"
              label-for="docId"
          >
            <b-form-input
                id="docId"
                v-model="item.docId"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Asunto"
              label-for="docSubject"
          >
            <b-form-input
                id="docSubject"
                v-model="item.docSubject"
            />
          </b-form-group>
        </b-col>
        </b-row>
      <b-row>
        <b-col>
          <b-form-group
              label="Remitente"
              label-for="docSender"
          >
            <b-form-input
                id="docSender"
                v-model="item.docSender"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Destinatario"
              label-for="docReceiver"
          >
            <b-form-input
                id="docReceiver"
                v-model="item.docReceiver"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Rango de fecha"
              label-for="docReceiver"
          >
            <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range', dateFormat: 'd-m-Y'}"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col>
          <b-button-group>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="loading"
                @click="searchJsonData()"
            >
              <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
              />
              <span class="align-middle" v-if="!loading">Buscar</span>
              <span class="align-middle" v-else>...</span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="rowData.length > 0">
      <ag-grid-vue
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          @grid-ready="onGridReady"
          :rowData="rowData"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :domLayout="'autoHeight'">
        >
      </ag-grid-vue>
    </div>
    <b-row v-if="rowData.length === 0">
      <b-col>
        No se encontraron registros en el rango de fecha.
      </b-col>
    </b-row>


    <!--    Toast-->
    <b-toast id="example-toast" variant="primary">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <b-img
              src="/logo.jpeg"
              class="mr-1"
              height="18"
              width="18"
              alt="Icon"
          />
          <strong class="mr-auto">Alerta</strong>
        </div>
      </template>
      <span>La fecha <strong>Desde</strong> debe ser menor a la fecha <strong>Hasta</strong>. </span>
    </b-toast>
  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BButtonGroup,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner, BFormFile, BForm
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import {AgGridVue} from "ag-grid-vue";
import environment from "@/environment";
import flatPickr from 'vue-flatpickr-component'
import vSelect from "vue-select";


export default {
  name: 'DocumentsList',
  components: {
    BForm, BFormFile,
    BButtonGroup,
    flatPickr,
    vSelect,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BCardCode,
    AgGridVue
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "N° Ctrl Vector",
          field: 'digesaId',
          sortable: true,
          resizable: true,
          minWidth: 200
        },
        {
          headerName: "N Documento",
          field: 'docId',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "Asunto",
          field: 'docSubject',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: "Remitente",
          field: 'docSender',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: "Destinatario",
          field: 'docReceiver',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: "Fecha de Documento",
          field: 'docDate',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: '',
          cellRenderer: params => this.linkRendererDrill(params.data._id),
          minWidth: 90
        },
        {
          headerName: '',
          cellRenderer: params => this.linkEditDocument(params.data._id),
          minWidth: 90
        },
      ],
      rowData: '',
      item: {
        policyType: '',
        policySubtype: ''
      },
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        resizable: true,
      },
      paginationPageSize: null,
      searched: false,
      loading: false,
      rangeDate: '',
      from: '',
      optionpolicyTypes: [{title: 'Tipo', value: ''}, {title: 'EMP', value: 'EMP'}, {
        title: 'VID',
        value: 'VID'
      }, {title: 'AUT', value: 'AUT'}],
      optionpolicySubtypes: [{title: 'Sub-Tipo', value: ''}, {
        title: 'Individual',
        value: 'INDIVIDUAL'
      }, {title: 'Colectivo', value: 'COLECTIVO'}]

    };
  },
  computed: {
    rangeFrom() {
      return this.rangeDate.substring(0, 10)
    },
    rangeTo() {
      if (this.rangeDate.length > 11) {
        return this.rangeDate.slice(-10)
      }
    }

  },
  beforeMount() {
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
      return '[' + params.value.toLocaleString() + ']';
    };

  },
  methods: {
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    refreshCells() {
      this.gridApi.refreshCells();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
    },
    onPageSizeChanged() {
      const value = document.getElementById('page-size').value;
      this.gridApi.paginationSetPageSize(Number(value));
    },

    searchJsonData() {
      let uri = `${environment.uri}processes/documents/search`
      const s = this.rangeDate.split(' to ')
      this.loading = true
      this.$http.post(uri, {
        page: 1,
        records: 1000,
        skip: 1,
        query: {
          digesaId: this.item.digesaId || "",
          docId: this.item.docId || "",
          docSubject: this.item.docSubject || "",
          docSender: this.item.docSender || "",
          docReceiver: this.item.docReceiver || "",
          since: s[0],
          until: s[1]
        }
      })
          .then(response => {
            this.rowData = response.data.data;
            this.loading = false
          })
          .catch(error => {
            console.log(error);
            this.loading = false
          });
    },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
          document.getElementById('filter-text-box').value
      );
    },

    linkRendererDrill(id) {
      const link = document.createElement('a');
      link.href = `${environment.uri}documents/download/${id}`;
      link.textContent = 'Ver Documento';
      link.className = 'detailsLink';
      link.target = 'blank'

      return link;
    },
    linkEditDocument(id) {
      const link = document.createElement('a');
      link.href = `/documents/edit/${id}`;
      link.textContent = 'Editar';
      link.className = 'detailsLink';

      return link;
    },
    statusRender(blocked) {
      if (blocked) {
        return 'Habilitado'
      } else {
        return 'Deshabilitado'
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
@import "@/assets/scss/custom-ag.scss";
@import '@core/scss/vue/libs/vue-select.scss';

</style>








